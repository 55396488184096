<template>
  <b-row class="d-flex align-items-center">
    <b-col>
      <b-form-input
        v-model="filterData.keyword"
        placeholder="Görev Adı, Firma Adı, Proje Adı..."
        @keydown.enter="getDataList"
      />
    </b-col>
    <b-col>
      <v-select
        v-model="filterData.id_task_statuses"
        :options="taskStatuses"
        label="title"
        :reduce="item => item.id"
        placeholder="Durum"
        multiple
        @input="getDataList"
      />
    </b-col>
    <b-col>
      <v-select
        v-model="filterData.id_priorities"
        :options="priorities"
        label="title"
        :reduce="item => item.id"
        placeholder="Öncelik"
        multiple
        @input="getDataList"
      />
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterCard',
  components: {
    BRow,
    BCol,
    BFormInput,
    vSelect,
  },
  props: {
    getDataList: {
      type: Function,
      required: true,
    },
  },
  computed: {
    taskStatuses() {
      return this.$store.getters['taskStatuses/dataList']
    },
    priorities() {
      return this.$store.getters['priorities/dataList']
    },
    filterData() {
      return this.$store.getters['tasks/filterData']
    },
  },
  created() {
    this.getPriorities()
    this.getTaskStatuses()
  },
  methods: {
    getTaskStatuses() {
      this.$store.dispatch('taskStatuses/getDataList')
    },
    getPriorities() {
      this.$store.dispatch('priorities/getDataList')
    },
  },
}
</script>
